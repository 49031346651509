import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { ThemeContext } from "@src/pages/_app";
import { userUriState } from "@src/state/auth.state";
import {
  giftCodeActivationState,
  giftCodeState
} from "@src/state/giftcode.state";
import { useRefreshState } from "@src/state/request.state";
import { riskYieldState } from "@src/state/risk-yield.state";
import { customerSupplierFromOriginOrConfigSelector } from "@src/state/supplier.state";
import { SButton } from "@src/styled-components/form/SButtons";
import { useAsyncMethod } from "@src/theme/hook/useAsyncMethod";
import useIsMobile from "@src/theme/hook/useIsMobile";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import type { FormInstance, InputRef } from "antd";
import { Button } from "antd";
import FormItem from "antd/lib/form/FormItem";
import Input from "antd/lib/input/Input";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { assertNotEmpty } from "smavesto.core";
import getGiftCodeObjectByGiftCodeString from "smavesto.core/lib/business-logic/gift-codes/getGiftCodeObjectByGiftCodeString";
import validateGiftCode from "smavesto.core/lib/business-logic/gift-codes/validateGiftCode";
import type { PrismicGiftCode } from "smavesto.core/lib/types/dto/cms/PrismicGiftCodeResponseDto";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

interface GiftCodeInputProps {
  source: "payment" | "onboarding";
  triggerValidationOnInput?: boolean /* Defines if the gift code gets checked about amounts */;
  parentForm?: FormInstance;
  expanded?: boolean;
}

const GiftCodeInput: React.FunctionComponent<GiftCodeInputProps> = ({
  source,
  triggerValidationOnInput,
  parentForm,
  expanded
}) => {
  const [error, setError] = useState<string>("");

  const [linkWithMoreInformations, setLinkWithMoreInformations] =
    useState<string>("https://www.smavesto.de/teilnahmebedingungen");

  const { theme } = useContext(ThemeContext);

  const isMobile = useIsMobile();

  const { inputCssProperties } = useContext(ThemeContext);

  const [giftCodes] = useAsyncMethod<PrismicGiftCode[]>(
    async () =>
      successOrUndefined(
        await smavestoCore.services.contentManagement.getGiftCodesFromPrismic()
      )?.documents || []
  );

  const [validatedGiftCode, setValidatedGiftCode] = useState<PrismicGiftCode>();

  const [isLoading, setLoading] = useState<boolean>(false);

  const stateType =
    source === "onboarding" ? "activeOnboardingOwner" : "currentDepotOwner";

  const [, riskYield] = useAsyncSelector(
    riskYieldState("activeOnboardingOwner")
  );

  const numberToValidate = riskYield?.startbetrag || 0;

  const [, supplierId] = useAsyncSelector(
    customerSupplierFromOriginOrConfigSelector("activeOnboardingOwner")
  );

  const [, userUri] = useAsyncSelector(userUriState(stateType));

  const refreshGiftCode = useRefreshState("giftcode", stateType);

  /* This put focus on the current field */
  const inputTagRef = useRef<InputRef>(null);

  const [isExpanded, setExpanded] = useState<boolean>(expanded || false);

  useEffect(() => {
    if (expanded === true) setExpanded(true);
  }, [expanded]);

  const [, giftcodeActivation] = useAsyncSelector(
    giftCodeActivationState(stateType)
  );

  const [, loadedGiftCode] = useAsyncSelector(giftCodeState(stateType));

  /* Remove the gift code */
  const removeGiftCode = async () => {
    assertNotEmpty(userUri);

    await smavestoCore.services.keyValueStore.setValue(
      userUri,
      "giftcode.code",
      "empty"
    );
    refreshGiftCode();
  };

  /* Persist the gift code */
  const submitGiftCode = async () => {
    assertNotEmpty(userUri);

    setLoading(true);

    setError("");

    const giftCodeInput = (inputTagRef.current?.input?.value || "")
      .toLowerCase()
      .trim();

    if (triggerValidationOnInput) {
      const validationResult = await validateGiftCode(
        smavestoCore,
        giftCodeInput,
        source,
        numberToValidate,
        theme,
        supplierId?.supplierID
      );

      if (isApiErrorResponse(validationResult)) {
        setError(validationResult.humanReadableError || "");
        setLoading(false);
        return;
      }

      /* Save the validated code (in terms of amount) */
      await smavestoCore.services.keyValueStore.setGiftCode(
        userUri,
        giftCodeInput
      );

      parentForm?.setFieldsValue({ giftcode: validationResult.data.code });

      setLinkWithMoreInformations(
        validationResult.data.conditions ??
          "https://www.smavesto.de/teilnahmebedingungen"
      );

      setValidatedGiftCode(validationResult);
    } else {
      const giftCodeObject = await getGiftCodeObjectByGiftCodeString(
        giftCodeInput,
        smavestoCore
      );

      if (!giftCodeObject) {
        setError("Der eingegebene Code ist nicht korrekt.");
        setLoading(false);
        return;
      }

      if (
        giftCodeObject.data.limit &&
        giftCodeObject.data.limit === "onboarding" &&
        source === "payment"
      ) {
        setError("Der eingegebene Code ist nicht korrekt.");
        setLoading(false);
        return;
      }

      if (
        giftCodeObject.data.enddate &&
        moment().isBefore(moment(giftCodeObject.data.startdate))
      ) {
        setError("Der eingegebene Code ist nicht gültig.");
        setLoading(false);
        return;
      }

      if (
        giftCodeObject.data.enddate &&
        moment().isAfter(moment(giftCodeObject.data.enddate))
      ) {
        setError("Der eingegebene Code ist nicht gültig.");
        setLoading(false);
        return;
      }

      if (
        giftCodeObject.data.limit &&
        giftCodeObject.data.limit === "payment" &&
        source === "onboarding"
      ) {
        setError("Der eingegebene Code ist nicht korrekt.");
        setLoading(false);
        return;
      }

      /* Save the validated code (in terms of amount) */
      await smavestoCore.services.keyValueStore.setGiftCode(
        userUri,
        giftCodeObject.data.code
      );

      setLinkWithMoreInformations(
        giftCodeObject.data.conditions ??
          "https://www.smavesto.de/teilnahmebedingungen"
      );

      parentForm?.setFieldsValue({ giftcode: giftCodeObject.data.code });
    }

    refreshGiftCode();
    setLoading(false);
  };

  useEffect(() => {
    if (!supplierId) return;

    const item = giftCodes?.find(
      a =>
        a.data.supplierids.length > 0 &&
        a.data.supplierids.some(sid => sid.supplierid === supplierId.supplierID)
    );

    if (supplierId && item && item.data.conditions) {
      setLinkWithMoreInformations(item.data.conditions);
    }
  }, [supplierId]);

  useEffect(() => {
    const validateGiftCodeAsync = async () => {
      if (triggerValidationOnInput && loadedGiftCode) {
        setValidatedGiftCode(
          successOrUndefined(
            await validateGiftCode(
              smavestoCore,
              loadedGiftCode.data.code,
              source,
              numberToValidate,
              theme,
              supplierId?.supplierID
            )
          )
        );
      } else if (triggerValidationOnInput) {
        setValidatedGiftCode(undefined);
      }
    };

    validateGiftCodeAsync();
  }, [loadedGiftCode, numberToValidate]);

  const giftCodeToHandle = triggerValidationOnInput
    ? validatedGiftCode
    : loadedGiftCode;

  /* Save the gift code */
  useEffect(() => {
    if (parentForm) {
      parentForm.setFieldsValue({ giftcode: giftCodeToHandle?.data.code });
    }
  }, [giftCodeToHandle]);

  return (
    <>
      <div
        style={{
          color: "inherit",
          maxWidth: 520,
          width: "100%",
          lineHeight: 2,
          borderRadius: 10,
          border: isExpanded
            ? "1px solid rgba(255,255,255,0.3)"
            : "1px solid rgba(255,255,255,0)",
          background: isExpanded ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0)",
          marginTop: 10,
          padding: 0,
          transition: "all .5s ease"
        }}
      >
        <Button
          style={{ color: "inherit", height: 60, width: "100%" }}
          type="text"
          icon={isExpanded ? <MinusCircleFilled /> : <PlusCircleFilled />}
          onClick={() => setExpanded(!isExpanded)}
        >
          {!giftCodeToHandle ? (
            <>Haben Sie einen Code, den Sie einlösen möchten?</>
          ) : (
            <>
              Sie haben diesen Code eingegeben:{" "}
              <b>{giftCodeToHandle.data.code.toUpperCase()}</b>
            </>
          )}
        </Button>
        <div
          style={{
            height: "100%",
            maxHeight: isExpanded ? 320 : 0,
            overflow: "hidden",
            transition: "all .5s ease"
          }}
        >
          {giftcodeActivation ? (
            <div style={{ padding: 15 }}>
              Sie haben in der Vergangenheit bereits einen Code eingelöst. Es
              kann nur ein Code pro Kunde verwendet werden. Es gelten die{" "}
              <a
                target="_blank"
                href={linkWithMoreInformations}
                rel="noreferrer"
              >
                Teilnahmebedingungen für Prämienaktionen
              </a>
              .
            </div>
          ) : (
            <div style={{ padding: 15 }}>
              <p>
                {giftCodeToHandle && (
                  <>
                    {giftCodeToHandle?.data.description && (
                      <p>
                        <b>Details:</b> {giftCodeToHandle?.data.description}
                      </p>
                    )}
                    <p>
                      {source === "onboarding" ? (
                        <>
                          Der Code wird automatisch aktiviert, sobald das
                          Onboarding abgeschlossen wurde. Die Einlösung erfolgt
                          gem. den{" "}
                          <a
                            target="_blank"
                            href={linkWithMoreInformations}
                            rel="noreferrer"
                          >
                            Teilnahmebedingungen für Prämienaktionen.
                          </a>
                        </>
                      ) : (
                        <>
                          Der Code wird automatisch aktiviert, sobald die
                          Einzahlung vorgenommen wurde. Die Einlösung erfolgt
                          gem. den{" "}
                          <a
                            target="_blank"
                            href={linkWithMoreInformations}
                            rel="noreferrer"
                          >
                            Teilnahmebedingungen für Prämienaktionen.
                          </a>
                        </>
                      )}
                    </p>
                  </>
                )}

                {!giftCodeToHandle ? (
                  <div
                    style={{
                      marginBottom: 25,
                      width: "100%",
                      maxWidth: 400,
                      gap: 10,
                      margin: "auto",
                      marginTop: 0,
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      justifyContent: "center"
                    }}
                  >
                    <Input
                      ref={inputTagRef}
                      name="giftcode"
                      placeholder="Code"
                      style={{
                        width: 300,
                        margin: "auto",
                        paddingLeft: 25,
                        height: 58,
                        ...inputCssProperties
                      }}
                    />
                    <SButton
                      loading={isLoading}
                      disabled={isLoading}
                      onClick={submitGiftCode}
                      type="primary"
                      style={{
                        width: "100%",
                        maxWidth: isMobile ? 300 : 100,
                        margin: "auto",
                        height: 60
                      }}
                    >
                      Einlösen
                    </SButton>
                  </div>
                ) : (
                  <SButton
                    style={{ marginBottom: 25 }}
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                      removeGiftCode();
                    }}
                    type="primary"
                  >
                    Code entfernen
                  </SButton>
                )}

                {error && (
                  <div style={{ color: "red", marginTop: 10, marginLeft: 35 }}>
                    {error}
                  </div>
                )}

                <div style={{ marginTop: 20 }}>
                  <b>Hinweis:</b> Es kann nur ein Code pro Kunde verwendet
                  werden. Es gelten die{" "}
                  <a
                    target="_blank"
                    href={linkWithMoreInformations}
                    rel="noreferrer"
                  >
                    Teilnahmebedingungen für Prämienaktionen
                  </a>
                  .
                </div>
              </p>
            </div>
          )}
        </div>
      </div>
      <FormItem name="giftcode">
        <Input style={{ minHeight: 0 }} hidden />
      </FormItem>
    </>
  );
};

export default GiftCodeInput;
